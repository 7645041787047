.mainContainerHeader {
	background-color: var(--cor-primary);
	width: 100%;
	height: 67px;

	display: flex !important;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.leftHeaderContainer {
	height: 100%;
	display: flex !important;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.titleHeaderContainer {
	height: 100%;
	display: flex !important;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.rightHeaderContainer {
	height: 100%;
	float: left;
	position: relative;
	display: flex !important;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

.rigthHeaderButtonSeacher {
	display: flex !important;	
	align-items: center;
}