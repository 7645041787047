.iconMenu {   
  border-radius: 50px;
  left: 0em;  
}

.mainContainer {    
  display: flex !important;
  flex-direction: column;
  width: 100vw  !important;
  height: 100vh;
  justify-content: "center"; 
  align-items: "center";
  max-width: 100% !important;
}

.information {    
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--cor-6);
  padding-bottom: 15px;
  padding-left: 15px;
}

.informationInitialCelular {      
  display: flex !important;
  flex-Direction: column;
  justify-content: center;  
  align-items: flex-end;    
  height: 20%;
  width: 100%;
} 

.informationInitialComputador {
  display: flex !important;
  flex-Direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 20%;
  width: 100%;   
  max-width: 900px !important;
} 

.grafic {
  background-color: var(--cor-6);
  display: flex !important;
  align-items: center;
  flex-Direction: column;
  justify-content: center;  
  padding: 0em !important;
  gap: 0px !important;
  width: 90%;
  height: 75%;
  max-width: 900px !important;
  max-height: 370px;
  border-radius: 10px 10px 10px 10px;   
}

.graficOptions {
  display: flex !important;
  flex-direction: row;
  cursor: pointer;    
}

.menu {  
  display: flex !important;
  background-color: var(--cor-7);  
  height: 60vh;
  max-width: 100% !important;
  padding: 0px !important;   

  align-items: center;
  flex-direction: column;
  justify-content: center;     
  overflow-y: auto;
  overflow-x: hidden; 
}

.graficDonuts {      
  width: 500px;
  display: flex;    
  flex-direction: row;
  margin: 0px 0px 0px 0px ;
}

.map {      
  width: 100%;   
  max-width: 100%;
  display : flex !important;
  flex-wrap: wrap; 
  justify-content: center;
  align-items: center;
}

.options-default {
  width: 165px !important;
}

.options-mobile {
  width: 145px !important;
}

.options {    
  background-color: var(--cor-6);
  display: flex !important;
  height: 145px;
  
  border-radius: 12px; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5em !important;

  cursor: pointer;      
  transition-property: unset;
  transition-duration: 0.5s;  
  transition: box-shadow 3s ease-in-out;
  opacity: 1;  
} 

.options:hover { 
  background-color: var(--cor-7); 
  border-radius: 10%; 
  box-shadow: none; 
  opacity: "0.8"; 
}

.icon { 
  color: var(--cor-primary); 
}

/*
  ************
  ** Mobile **
  ************
*/
@media only screen and (max-width: 850px) {
  .menu {  
    align-items: flex-start;
    justify-content: flex-start;
  }
  .map {
    margin-top: 20px;
  }
  .baseBoard {
    position: absolute;
    bottom: 0;
  }
}