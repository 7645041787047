.mainf {
  display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: 0;
	overflow-y: hidden;	
  /* background-color: rgb(200, 221, 221); */
}

.icone {
  /* background-color: rgb(123, 135, 135); */
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mensagem {
  /* background-color: rgb(58, 95, 95); */
  height: 40%;
  display: flex;
  flex-direction: column;
}

.mensagemTitle {
  /* background-color: rgb(190, 225, 225); */
  display: flex;
  justify-content: center;
  align-items: center;
  
  font-family: var(--font-terceary);  
  font-size: xx-large;
  font-weight: normal;
  height: 30%;
}

.mensagemCorpo {
  /* background-color: rgb(183, 208, 208); */      
  color: var(--cor-cinza);
  overflow-x: hidden;
  overflow-y: hidden;

  padding: 10px;
  word-wrap: break-word;
  text-align: center;
}

.botoes {
  /* background-color: rgb(144, 79, 181); */
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo1 {
  /* height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.ui-success,
.ui-error {
  width: 100px;
  height: 100px;
  margin: 40px;
}

.ui-success-circle,
.ui-error-circle {
  fill: none;
}

.ui-success-circle,
.ui-error-circle,
.ui-success-path,
.ui-error-line1,
.ui-error-line2 {
  stroke-width: 4;
  stroke-linejoin: round;
}

.ui-success-circle,
.ui-error-circle {
  stroke-dasharray: 260.75219024795285px;
  stroke-dashoffset: 260.75219024795285px;
  animation: drawCircle 1s ease forwards;
}

.ui-success-path {
  stroke-dasharray: 60px, 64px;
  stroke-dashoffset: 62px;
  animation: drawPath 0.4s 1s ease forwards;
}

.ui-error-line1,
.ui-error-line2 {
  stroke-dasharray: 54px, 55px;
  stroke-dashoffset: 55px;
  animation: drawLines 0.15s 1.2s linear forwards;
}

@keyframes drawCircle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes drawPath {
  0% {
    stroke-dashoffset: 62px;
  }
  65% {
    stroke-dashoffset: -5px;
  }
  84% {
    stroke-dashoffset: 4px;
  }
  100% {
    stroke-dashoffset: -2px;
  }
}

@keyframes drawLines {
  to {
    stroke-dashoffset: 0;
  }
}




.ui-attention {
  width: 100px;
  height: 100px;
  margin: 40px;
}

.ui-attention-circle {
  fill: none;
}

.ui-attention-circle,
.ui-attention-triangle,
.ui-attention-line {
  stroke-width: 4;
  stroke-linejoin: round;
}

.ui-attention-circle {
  stroke-dasharray: 260.75219024795285px;
  stroke-dashoffset: 260.75219024795285px;
  animation: drawCircle 1s ease forwards;
}

.ui-attention-triangle {
  fill: none;
  stroke-dasharray: 90px;
  stroke-dashoffset: 90px;
  animation: drawTriangle 0.5s 1.5s ease forwards;
}

.ui-attention-line {
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
}

@keyframes drawCircle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes drawTriangle {
  to {
    stroke-dashoffset: 0;
  }
}



/* Animação para entrada do modal */
.slide-enter {
  transform: translateY(-100%) scale(0.8);
}
.slide-enter-active {
  transition: transform 300ms ease-in-out;
  transform: translateY(0%) scale(1);
}

/* Animação para saída do modal */
.slide-exit {
  transform: translateY(0%) scale(1);
}
.slide-exit-active {
  transition: transform 300ms ease-in-out;
  transform: translateY(-100%) scale(0.8);
}