pageRoot
scroll

.iconAdd {
  position: fixed;
  bottom: 30px;
  right: 30px;
  color: var(--cor-primary);
}

.record {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
}

.scroll {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  cursor: pointer;
}