.geral {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  justify-content: "center"; 
  align-items: "center";
}
.cabecalho {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
  background-color: var(--cor-6);
}
.iconGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 250px;
}
.iconGroupSmall {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100px;
  margin-left: -12px;
  background-color: var(--cor-primary);
  clip-path: 
    polygon(0% 0%, 70% 0%, 100% 50%, 
            70% 100%, 0% 100%, 30% 50%
           );
}

.iconGroupSmall2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100px;
  margin-left: -12px;
  background-color: var(--cor-5);
  clip-path: 
    polygon(0% 0%, 70% 0%, 100% 50%, 
            70% 100%, 0% 100%, 30% 50%
           );
}

.spaceName{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 100px;
  background-color: var(--cor-primary);
  margin-left: -12px;
  margin-right: -7px;
  clip-path: 
    polygon(0% 0%, 86% 0%, 100% 50%, 
            86% 100%, 0% 100%, 14% 50%
           );
}

.spaceInitial{
  width: 50px;
  height: 100px;
  background-color: var(--cor-primary);
  margin-left: -45px;
  margin-right: -25px;
}

.name{
  font-weight: bold;
  font-size: 22px;
  color: var(--cor-6);
  margin-left: 20px;
}

.risco{
  background-color: var(--cor-5);
  align-items: center;
  height: 3%;
  width: 15%;
}
.risco2{
  background-color: var(--cor-primary);
  align-items: center;
  height: 3%;
  width: 15%;
}

.body1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 250px);
  width: 100%;
  background-color: var(--cor-6);
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--cor-6);
}

.passos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
}

.rodape {
  display: flex;
  justify-content: space-between;
  height: 100px;
  background-color: var(--cor-6);
}
