.paperContainer {
  width: 100.00%;
  height: 100.00%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow-y: auto;
}

.filterContainer {
  width: 100.00%;
  height: 9.50%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 1.00em;
  padding: 1.00em;
}

.reportContainer {
  width: 100.00%;
  height: 90.50%;
  padding: 1.00em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.00em;
}

.generateReportButton { height: 50.00px; }



/* 
  ************
  ** Mobile **
  ************
*/
@media screen and (max-width: 825px) {
  .filterContainer { height: 25.00%; gap: 7px; }
  .reportContainer { height: 75.00%; justify-content: flex-start; gap: 7px; }
}