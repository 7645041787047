
.header {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  height: 72px;
}

.components {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  overflow-y: auto;
  background-color: var(--cor-6);
  padding: 0px !important;
}

.gerenciamentoCSC {
  display: flex !important;
  padding: 0px;
  align-items: flex-start;
  flex-direction: row;
  gap: 0.5em; 
  flex-wrap: wrap;
  width: 100%;
}

.dataTributacao {
  display: flex !important;
  padding: 5px 0px 0px 0px !important;
  flex-direction: row;
  gap: 1.3em;
  flex-wrap: wrap;
  width: 100%;
  overflow-x: hidden; 
}

.iconTypografy {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.expiredLabel{
  color: var(--cor-terciary);
}