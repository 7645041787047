.containerPdf {
  /* background-color: rgb(179, 133, 74); */
  height: 88.9%;
  width:  99.99%;
  cursor: pointer;  

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; 
}

.container-mobile {
  height: 30%;
  width:  100%;
}

.containerImpressaoVisualizacao {
  /* background-color: antiquewhite; */
  height: 100%;
  width:  99.99%;
  cursor: pointer;  

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; 

}

.containerImpressaoVisualizacao2 {
  /* background-color: antiquewhite; */
  height: 90%;
  width:  99.99%;
  cursor: pointer;  

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; 

}

.iconDescricaoImpressao {    
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  transition: all 0.4s ease;  
  opacity: 1;
  gap: 7px;
}

.iconDescricaoImpressaoFonteCelular {
  font-size: 12px;
}
.iconDescricaoImpressao:hover {
  background-color: var(--cor-primary);  
  height: 100%;
  width: 100%;
  color: white;  
  /* font-weight: bold;   */
}

.teste2 {
  /* background-color: red; */
  display: flex;
  height: 100vh;
  width: 100%;
}