.clienteSearchModalDialogPaper {
  min-height: 92.00% !important;
  max-height: 92.00% !important;
}

.clienteSearchModalDialogContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;
  
  overflow-y: hidden !important;
}

.clienteTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.clienteSearchModalTableRow {
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.30s ease;
}

.clienteSearchModalTableRow:hover {
  background-color: #e0e0e0;
}

.clienteSearchModalTableCell {
  border-radius: 7px;
  text-align: left;
}


/* 
  ************
  ** Mobile **
  ************
*/
@media screen and (max-width: 825px) {
  .clienteSearchModalDialogPaper {
    min-height: 100.00% !important;
    max-height: 100.00% !important;
  }
}