.status {
  border-radius: 20px;
  bottom:"-6px";
  position: "relative"; 
  min-width: "20px";
  min-height: "20px";
  width: "20px"; 
  text-align: "left"; 
  display: "inline-block";
}

.status-cancelada {
  background-color: #CE6062;
  border-radius: 20px;
  color: white;
}

.status-pendente {
  background-color: #f5c03a;
  border-radius: 20px;
  color: white;
}

.status-inutilizada {
  background-color: rgb(168, 172, 173);
  border-radius: 20px;
  color: white;
}

.status-autorizada {
  background-color: rgb(79, 158, 189) !important;
  border-radius: 20px;
  color: white;
}

.label-status {
  padding: 5px;
}

.vendas-record {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  height: calc(100vh - 150px);
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}