.mainContainer {    
  display: flex !important;
  flex-direction: column; 
  overflow-y: hidden;    
  padding: 0px !important;
}

.mainDesktop {  
  height: 600px;
  width: 450px;
  position: absolute;
  top: calc(50% - 700px / 2); 
  left: calc(50% - 450px / 2); 
  
  padding: 1px !important;
  border-radius: 10px 10px 10px 10px ;
}

.mainDesktop2 {  
  display: flex !important;
  overflow-y: hidden;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
  height: 100%;
  width: 100%;
}

.login-backGround {
  background: linear-gradient(-40deg,#5a7fc7, #4f9ebd, #38b8b6);
  height: 100%;
}

.logo {    
  height: 50%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoSmall {    
  height: 50%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg,#5a7fc7, #4f9ebd, #38b8b6) !important;
  overflow-y: hidden;
}

.login {
  background-color: var(--cor-6);
  height: 70%;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  padding: 15px 15px 0px 15px;
  display: flex !important;
  align-items: center;
  flex-Direction: column;
  justify-content: center;
  overflow-y: hidden;
}

.loginDesktop {
  background-color: var(--cor-6);
  min-height: 24em;
  height: 50%;
  width: 500px;
  border-radius: 10px;
  display: flex !important;
  align-items: center;
  flex-Direction: column;
  justify-content: center;
}

#titulo {
  font-size: 2.2em;
  font-weight: 900;
  margin-bottom: -30px;
}

#titulo2 {
  font-size: 5.5em;
  font-weight: 900;
}

#logoTipoSmall {
  width: 12em;

}

#logoTipoDesktop {
  width: 22em;
}

#logoFx {
  width: 1.8em;
  display: flex !important;
  margin-bottom: 0px; 
  margin-top: 30px;
}

.form {
  margin: 0 auto;    
}

.floatingButton {
  transition: transform 0.3s;    
}

.floatingButton:hover {
  transform: translateY(-5px);
}

#tituloLogin {
  font-size: 25px; 
  color: var(--cor-primary);    
  margin: 15px 0px 0px 30px
}

#tituloLogin2 {
  font-size: 12px;
  margin: 0px 0px 0px 30px;
  color: lightslategrey;
}

