/*
  ****************
  ** Containers **
  ****************
*/
.loadingMainContainer {
  width: 100.00vw;
  height: 100.00vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.loadingSubContainer {
  background-color: var(--cor-secundary);
  width: 90.00%;
  height: 90.00%;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
}

/* 
  ************************
  ** Image SubContainer **
  ************************
*/
.loadingSubContainerImage {
  background-color: #363636;
  width: 100.00%;
  height: 70.00%;
  border-radius: 7px 7px 0px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.loadingSubContainerImage1 { width: 50.00%; height: 80.00%; }

/*
  ***********************
  ** Text SubContainer **
  ***********************
*/
.loadingSubContainerText {
  width: 100.00%;
  height: 30.00%;
  border-radius: 0px 0px 7px 7px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.loadingSubContainerText1 { font-size: 2em; text-align: center; }

.loadingSubContainerText2 { font-size: 1.75em; text-align: center; }

.loadingContainer { width: 200px; height: 60px; position: relative; z-index: 1; }

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #363636;
  left: 15%;
  transform-origin: 50%;
  animation: circleBounce 0.50s alternate infinite ease;
}

.circle:nth-child(2) { left: 45%; animation-delay: 0.20s; }

.circle:nth-child(3) { left: auto; right: 15%; animation-delay: 0.30s; }

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadowStretch 0.50s alternate infinite ease;
}

.shadow:nth-child(4) { left: 45%; animation-delay: 0.20s }

.shadow:nth-child(5) { left: auto; right: 15%; animation-delay: 0.30s; }

/* 
  ****************
  ** Animations **
  ****************
*/
@keyframes circleBounce {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.70);
  }

  40% { height: 20px; border-radius: 50%; transform: scaleX(1); }

  100% { top: 0%; }
}

@keyframes shadowStretch {
  0% { transform: scaleX(1.50); }

  40% { transform: scaleX(1); opacity: 0.70; }

  100% { transform: scaleX(0.20); opacity: 0.40; }
}

/*
  ************
  ** Mobile **
  ************
*/
@media only screen and (max-width: 850px) {
  /* 
    ************************
    ** Image SubContainer **
    ************************
  */
  .loadingSubContainerImage1 { width: 80.00%; }

  /* 
    ***********************
    ** Text SubContainer **
    ***********************
  */
  .loadingSubContainerText1 { font-size: 1.35em; }

  .loadingSubContainerText2 { font-size: 1.15em; }

  .shadow { top: 52px; }

  /* 
    ****************
    ** Animations **
    ****************
  */
  @keyframes circleBounce {
    0% {
      top: 50px;
      height: 5px;
      border-radius: 50px 50px 25px 25px;
      transform: scaleX(1.70);
    }
  
    40% { height: 20px; border-radius: 50%; transform: scaleX(1); }
  
    100% { top: 0%; }
  }
}