.containerMain {   
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pagamento {    
  height: 60%;
  width: 100%;
  padding: 10px 15px 5px 15px;
}

.pagamentoInformacao {  
  width: 100%;
  height: 100%;   
}

.containerSubTotal {
  margin-bottom: 3px;
  margin-left: 10px;
  margin-right: 10px;    
}

.pagamentosTitle {  
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
  letter-spacing: -1px;
  height: 1.4em;
}

.pagamentoDesconto {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--cor-primary);
  font-size: 14px;
  padding: 0;
}

.containerPagamento {
  overflow-x: auto;
}

.containerItensPagamento {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}

.containerItensValorDelete{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px
}

.negritoPreco {  
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -1px;
}

.opcoes {
  height: 40%;
  width: 100%;
}

.infPagamento {
  padding: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.opcoesPagamento {
  width: 40%;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);  
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.pagamentoOpcoes {
  height: 70%;
  min-height: 8em; 
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  gap: 10px;
  overflow-y: auto; 
}

.pagamentoOutros {  
  box-shadow: 0 0 10px rgba(0,0,0,0.1);  
  height: 80%;  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;  
  padding: 10px;
  border-radius: 10px;
}

.opcaoButton {
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.containerOutros {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
  padding-top: 5px;
}

.divButtonPagamento {  
  width: 100%;   
  border-radius: 7px;
  box-shadow: 0 0 0.5px var(--cor-primary), 0 0 3px;   
}
