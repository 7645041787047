.flex {
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.searchTextField {
	display: flex;
	width: 100%;
}

.main {		
	display: flex;
	flex-direction: row;
	height: 100vh;
	width: 100%;
	padding: 0;
	overflow-y: hidden;	
}

.containerProducts {		
	display: flex;	
	flex-direction: column;
	height: 100%;	
	border-right: 1px solid #cccccf;
}

.search {	
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 15%;
}

.divSearch {
	width: 100%; 
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	height: 60%;
	padding: 1em;
}

.containerTitleProduto {
	height: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.fieldSearch {	
	display: flex;
}

.products {	
	display: flex;
	flex-wrap: wrap;	
	justify-content: space-around;
	align-items: center;
	gap: 2em;	
	overflow-y: auto;			
	height: 70%;
	margin-bottom: 20px;
	
}

.productsInformations {
	height: 150px;
	width: 150px;  
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;	
	position: relative;
}

.productsIcon {			
	height: 33%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: hidden;
}

.productsName {
	flex-grow: 1; 
	line-height: 1.2;
	font-size: 13px;
  text-align: center; 
	color: #3b3c3f;
	height: 30%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.productsQtde {
	background-color: var(--cor-primary);
	width: 100%;
	height: 22%;
	overflow-y: hidden;
	border: 1px solid var(--cor-primary);
	border-radius: 5px;
	color: white;	
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.productsQtdeMaior {
	width: 100%;
	height: 22%;
	overflow-y: hidden;
	border: 1px solid var(--cor-7);
	border-radius: 5px;	

	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
	cursor: pointer;
}

.divExcluirItem {
	height: 100%;
	width: 20%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.divQtdeItem {
	width: 60%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.divAddItem {
	width: 20%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.productsPreco {	
	height: 15%;
	width: 100%;
	display: flex;
	justify-content: center;
	font-size: 18px;
	color: var(--cor-cinza);
	letter-spacing: -1px;	
}

.productsPreco5 {    
	height: 30%;
	width: 100%;
	display: flex;
	justify-content: center;
	font-size: 18px;
	font-family: 'Courier New', Courier, monospace;
	color: var(--cor-cinza);
	letter-spacing: -1px;
}

.cfe {
	height: 70%;
	overflow-y: auto;
	padding: 10px;
}

.itensDescription {
	display: flex;
	justify-content: space-between;
	margin: 3px;
}

.itensTotais {	
	display: flex;
	margin: 3px;
	color: var(--cor-cinza)
}

.pagamentoCfe {		
	height: 30%;
	border-radius: 5px;
}

.pagamentoTitle {	
	height: 12%;
}


.containerInformationPag {	
	height: 40%;		
	display: flex;
	justify-content: space-between;	
	padding: 5px;
}

.buttonPagamento {	
	height: 50%;	
	width: 100%;
	display: flex;
	text-align: center; 	
	margin-top: -1.3px;
	justify-content: center;	
}

.buttonPagamentoProducts {
	height: 16.7%;
	width: 100%;
	display: flex;
	justify-content: center;
}

.divResumo {	
	width: 100%;
	display: flex;
	justify-content: center;
}

.counter {
	position: absolute;
	margin-top: 2px;
	height: 2.3em;
	width: 2.3em;
	top: 0;
	left: 0;
	background-color: var(--cor-primary);
	color: white;
	border-radius: 50%;
	padding: 4px 8px;
	font-size: 9px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.counterTotalProducts {
	background-color: #FF004D;
	border-radius: 50%;	
	width: 17px;
	height: 17px;	
	border-radius: 50%;		
	display: flex;
	align-items: center;
	justify-content: center;	
	font-size: 9px;
	top: -1.4em;
	left: -2em; 
	position: relative; 
}

.carrinho {	
	height: 50px;
	width: 100%;	
	display: flex;
	align-items: center;
	font-size: 14px;
	opacity: 1;	
	background-color: var(--cor-5);
	color: white;
	transition: all 0.5s ease;
}

.verCarrinho {
	height: 100%;
	width: 50%;
	display: flex;
	align-items: center;
}

.SubTotalCarrinho {
	height: 100%;
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	padding-right: 20px;
	letter-spacing: -1px; 
	line-height: 1.4;
}

.campoEspecie {
	display: flex;
	justify-content: center;
	align-items: center;
}