.containerCalculadora {  
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerCpfCnpj {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;  
}

.opcaoButton {  
  gap: 5px;
  padding: 5px;
}

.calculadora {   
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 90%;
}

.buttonCalculadora {
  color: black;
  background-color: inherit;
  border: none;
  font-size: 25px;
  opacity: 1;
  width: 70px;
  height: 55px;
}

.numeros {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;    
}
