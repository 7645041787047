.dataCompany {	
  display : flex;    
  flex-wrap: wrap;   
	flex-direction: column;
  padding: 10px 10px 0px 10px;	
	width: 100%;	
}

.iconAccordionSummary {	
	display: flex;
	justify-content: center;
	align-items: center;	
	text-align: center;
}
.dialogContent {	
	display: flex;
	flex-direction: column;
}

.info {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;	
	width: 100%;
}

.endereco {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;	
	width: 100%;
}

.apuracao {	
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;
}

.regimeTributarioFields {	
	display: flex;
	flex-direction: row;
	align-items: center;
	/*justify-content: center;*/
	flex-wrap: wrap;
	width: 100%;	
	height: 100%;
	gap: 10px;
	padding: 0px 10px 10px 10px;
}

.zipPostal {
	display: flex;
	gap: 10px;
}

.situacao-autorizada {
	color: rgb(79, 158, 189);
}

.situacao-cancelada {
	color: #CE6062;
}

.situacao-inutilizada {
	color: rgb(168, 172, 173);
}

.situacao-pendente {
	color: #f5c03a;
}

input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0; 
}