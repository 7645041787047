.produto-dataCompany {
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 10px 20px 10px 20px; 
  width: 100%;
}

.dialogContent {
  flex-direction: column;
}

.produto-paper {
  display: "flex" !important;
  align-items: "center";
  max-width: 100%;
  max-height: 165px;
  width: auto;
  height: auto;
  cursor: pointer;
}

.produto-image {
  display: flex !important;
  width: 250px;
  height: 208px;
  justify-content: center !important;
}

.produto-image2 {
  height: 80%;
  width: 190px;
  cursor: pointer;
}

.produto-dados {
  display: flex !important;
  padding: 0px !important;
  flex-direction: row;
  gap: 1em;
}
.produto-dadosSmall {
  display: flex !important;
  padding: 0px !important;
  flex-direction: column;
  gap: 1em;
}

.produto-info {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0px !important;
  width: 100%;
}

.infoTributacao {
  padding: 20px 0px 0px 0px !important;
  flex-wrap: wrap;
  gap: 1em;
  flex-direction: row;
  width: 100%;
  overflow-x: hidden;
}

.produto-tributacao-linha {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.50em;
  width: 100.00%
}

.produto-tributacao-sublinha {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.50em;
  width: 100%;
}

.produto-tributacao-botao {
  display: flex;
  height: 50px;
}


/*
  ************
  ** Mobile **
  ************
*/
@media screen and (max-width: 825px) {
  .produto-tributacao-linha { flex-direction: column; }
  .produto-tributacao-sublinha { width: 100.00%; }
}