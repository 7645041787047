.dataCompany {	
  display : flex !important;    
  flex-wrap: wrap;   
	flex-direction: column;
  padding: 10px 10px 0px 10px;	
	width: 100%;	
	max-width: 100% !important;
	padding: 0px !important;
}

.iconAccordionSummary {	
	display: flex !important;
	justify-content: center;
	align-items: center;	
	text-align: center;
}
.dialogContent {	
	display: flex !important;
	flex-direction: column;
	padding: 0px !important;
}

.info {
	display: flex !important;
	padding: 0.5em !important;
	flex-wrap: wrap;
	gap: 0.5em;	
	width: 100%;
}

.endereco {
	display: flex !important;
	padding: 0.5em !important;
	flex-wrap: wrap;
	gap: 0.5em;	
	width: 100%;
}

.apuracao {	
	display: flex !important;
	flex-wrap: wrap;
	gap: 0.5em;
}

.regimeTributarioFields {	
	display: flex !important;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;	
	height: 100%;
	gap: 10px;
	padding: 0px 10px 10px 10px !important;
}

.zipPostal {
	display: flex !important;
	padding: 0px !important;
	gap: 10px;
	margin: 0px !important;
}

.cliente-inscricao {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5em;
  width: 100%;
}

.cliente-linha {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5em;
  width: 100%;
}



/*
	****************
	** Animations **
	****************
*/
@keyframes spin {
	0% {color: white; transform: rotate(0deg) }
	100% {color: white; transform: rotate(360deg) }
}



/*
	************
	** Mobile **
	************
*/
@media screen and (max-width: 825px) {
  .cliente-linha {flex-direction: column;}
}