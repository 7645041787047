.campos {
  display: flex !important;
  gap: 20px; 
  }
  
#icon {  
  font-size: 1em;
  display: flex;
  align-items: baseline;
  justify-content: center;
  }
    
.table {
  display: flex;
  align-items: center;  
  }
  
.icone {
  font-size: 30;
  display: flex;
  flex-direction: row;
  color: red;
}  
  
.iconAdd {
  position: fixed !important;
  bottom: 30px;
  right: 30px;
  color: var(--cor-primary);    
}  

.searchField {  
  transition: all 0.4s ease;
  border: none;
  border-color: red;  
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.record {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  height: calc(100vh - 150px);
}

.scroll {  
  width: 100%;
  height: 100%;
  overflow-y: auto;  
  cursor: pointer;
  background-color: var(--cor-6) !important;
}